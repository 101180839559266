import React, { useState } from 'react';
// import UserItem from "./UserItem";
import { Button, ButtonGroup, CircularProgress, Typography } from "@mui/material";
import { useEffect } from 'react';
// import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import Div from '@jumbo/shared/Div';
import ApifyClient from 'apify-client';
import { useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import AutomatedGraphicItem from './AutomatedGraphicItem';
import { hraxios, scriptaxios } from 'app/services/config';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { CLOUDFRONT_URL } from 'app/utils/constants/urls';

const AutomatedGraphicsDetailData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [canExport, setCanExport] = useState(false);
    const [view, setView] = React.useState("grid");
    const { state } = useLocation();
    const navigate = useNavigate();

    const [automatedGraphicsDetailData, setAutomatedGraphicsDetailData] = React.useState([]);
    const Swal = useSwalWrapper();
    const sweetAlerts = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
        }).then(result => {
            navigate('/automated-graphics')
        });
    };
    const onImgUpload = async (form_Data) => {
        const response = await hraxios.post(`automated_graphics/upload`, form_Data);
        return response?.data?.data
    }
    const removeBgFromGraphic = async () => {
        let graphicData = [];
        for (let i = 0; i < state?.rows?.length; i++) {
            const form = new FormData()
            const processImg = state?.rows[i];
            let blob = await fetch(processImg["Product Image"]).then(r => r.blob());
            form.append('image_file', blob)
            const response = await fetch('https://clipdrop-api.co/remove-background/v1', {
                method: 'POST',
                headers: {
                    'x-api-key': '67a8b01eae4a0e1e9f3b6e7c3e4ab27b3830cc7595e47d79887c81d90537989a30f60b6693eee1146e5ab6aee0344c40',
                },
                body: form,
            })
            const arrayBuffer = await response.arrayBuffer();
            // buffer here is a binary representation of the returned image
            const fileId = uuidv4()
            const file = new File([arrayBuffer], `img_${fileId}.png`, { type: 'image/png' });
            let form_Data = new FormData();
            form_Data.append("attachment", file);
            let responseImg = await onImgUpload(form_Data);
            let updateGraphicsData = {
                ...state?.rows[i],
                ...{
                    ["Product Image Without BG"]: CLOUDFRONT_URL + responseImg?.id?.base_url + "/" + responseImg?.id?.key,
                }
            }
            const crypto = require("crypto-js");
            const signedRenderLink = getSignedRenderLink({
                apiKey: "YHCRIXGOItVt0UKNLlyHcaiZdJZIleOquT8KH8Fx",
                format: "png",
                templateId: state?.templateId,
                modifications: updateGraphicsData
                // modifications: {
                //     "scale": 1,
                //     "Amazon Logo": "https://robolly.s3.eu-central-1.amazonaws.com/organizations/632de8157558dc8f2060a2d8/uploaded/7PYRrDPGpmrUUP0YBTVhQdfXpxgoXyhJ5IjympGRVuXdOcEtF3WIvH8hUQhIURLlZjsUBA.png",
                //     "Product Image": item?.productImages,
                //     "Product Name": item?.productName,
                //     "Background Image": item?.backgroundImages,
                // },
            });
            // }
            let productName = '';
            if (state?.templateName === "Live Gaming Thumbnail") {
                productName = state?.rows[i]?.['Influencer Name ']
            } else if (state?.templateName === "Live Product Graphic") {
                productName = state?.rows[i]?.['Product Name 1']
            } else {
                productName = state?.rows[i]?.['Product Name']
            }
            graphicData.push({
                productName: productName,
                // productName: updateGraphicsData?.['Product Name'],
                productLink: signedRenderLink
            });
            // //console.log("Signed render link -->", signedRenderLink);
            function getSignedRenderLink({ templateId, modifications, apiKey, format = "jpg" }) {
                const query = new URLSearchParams(modifications).toString();
                // //console.log("crypto response---", crypto.HmacSHA256("Message", "Key"));
                const signature = crypto.HmacSHA256("sha256", apiKey)
                // const signature = crypto.HmacSHA256("sha256", apiKey).update([templateId, format, query].join(":")).digest("hex");
                const url = `https://api.robolly.com/templates/${templateId}/render.${format}?${query}&sig=${signature}`;
                return url;
            }
        }
        setAutomatedGraphicsDetailData(graphicData)
        setIsLoading(false)
        setCanExport(false)
    }
    useEffect(() => {
        if (state?.rows?.length > 0) {
            setIsLoading(true)
            setCanExport(true)
            removeBgFromGraphic();
        } else {
            sweetAlerts('Something went wrong!');
            setIsLoading(false)
        }
    }, [])
    const handleExport = async () => {
        const mockData = automatedGraphicsDetailData.map((item, index) => {
            let staticColumn = ({
                "id": index + 1,
                "productName": item?.productName,
                "productLink": item?.productLink,
            })
            return staticColumn;
        })
        function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';
            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += '++'

                    line += array[i][index];
                }
                const convertedSentence = line.split("++").map(word => `"${word}"`).join(",");
                str += convertedSentence + '\r\n';
            }
            return str;
        }

        function exportCSVFile(headers, items, fileTitle) {
            if (headers) {
                items.unshift(headers);
            }
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);
            var csv = convertToCSV(jsonObject);

            var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        var headers = {
            id: "ID",
            productName: "Product Name",
            productLink: "Product Link",
        };
        // const sortedGraphicsData = [...mockData].sort((a, b) => {
        //     const productImagesA = state.find((item) => item.productName === a.productName);
        //     const productImagesB = state.find((item) => item.productName === b.productName);
        //     return productImagesA.id - productImagesB.id;
        // });
        var fileTitle = 'Graphics Data Export'; // or 'my-unique-title'
        exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    }
    const handleExportZip = async () => {
        setCanExport(true)
        const image_data = automatedGraphicsDetailData.map((item, index) => {
            return {
                "url": item?.productLink,
                "name": item?.productName,
            }
        })
        const response = await scriptaxios.post(`api/download_images`, { image_data: image_data }, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data]);
        saveAs(blob, `${state?.templateName}.zip`);
        // saveAs(blob, 'automatedGraphics.zip');
        setCanExport(false)
    }
    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Automated Grapics Card View</Typography>
            <div style={{ height: '70px', width: '100%' }}>
                <Button sx={{ float: 'right' }} disabled={canExport} variant="contained" onClick={handleExportZip}>
                    Export CSV
                </Button>
            </div>
            {isLoading ?
                <Div sx={{ flexGrow: 1 }}>
                    <CircularProgress color='primary' sx={{ marginLeft: '50%', marginTop: '20%' }} />
                    <Typography sx={{ marginLeft: '25%' }}>
                        <span>Getting your data...</span>
                        <span>it usually takes around 2-3 minutes to start seeing results</span>
                    </Typography>
                </Div>

                :
                <>
                    {
                        automatedGraphicsDetailData?.map((graphicsData, index) =>
                            <AutomatedGraphicItem graphicLink={graphicsData} key={index} />
                        )
                    }
                    {/* {
                        view === "grid" ? (
                            automatedGraphicsDetailData.map((graphicsData, index) =>
                                <AutomatedGraphicItem asinItem={graphicsData} key={index} />
                            )) : (
                            <AsinNumberTableView asinNumberTableData={automatedGraphicsDetailData} />
                        )
                    } */}
                </>
            }
        </React.Fragment>
    );
};

export default AutomatedGraphicsDetailData;
