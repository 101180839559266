import * as yup from "yup";
import React, { useEffect, useState } from 'react';
// import {useMutation} from "react-query";
// import {contactService} from "../../../../../services/contact-services";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, TextField, Typography, InputLabel, MenuItem, Select } from '@mui/material'; //import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Form, Formik, ErrorMessage } from "formik";
import DndWrapper from "./DndWrapper";
import { useDropzone } from "react-dropzone";
import subTaskService from "app/services/subtask_service";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { hraxios } from "app/services/config";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import moment from "moment";
import { CLOUDFRONT_URL } from "app/utils/constants/urls";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};
const OfficeExpenseForm = ({ expanseId }) => {
    const Swal = useSwalWrapper();
    const [files, setFiles] = useState([]);
    const [expenseDoc, setExpenseDoc] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [otherValue, setOtherValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [expenseData, setExpenseData] = React.useState({});
    const { hideDialog } = useJumboDialog();
    const { setAuthToken } = useJumboAuth();

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*,application/pdf',
        onDrop: acceptedFiles => {
            //console.log("onDrop files-");
            onFileUploadInput(acceptedFiles)
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
    });
    React.useEffect(() => {
        if (expanseId !== undefined) {
            // //console.log("expanseId edit api call");
            subTaskService.getHrExpenseDataById(expanseId).then((data) => {
                setExpenseData(data?.data?.data)
                setExpenseDoc(data?.data?.data?.bill_reciept_documents)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
        }
    }, [expanseId])
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ));
    const onFileUploadInput = async (file) => {
        // const file = document.getElementById("fileuploadinput-").files;
        const fileLength = file.length;
        let allDoc = []
        setIsLoading(true)
        if (fileLength > 0) {
            for (let i = 0; i < fileLength; i++) {
                let form_Data = new FormData();
                form_Data.append("attachment", file[i]);
                const response = await hraxios.post(`hr_office_expenses/expenses-upload`, form_Data);
                // const response = await ajax(CONSTANTS.API_METHODS.POST, CONSTANTS.API.SUB_TASK_VIDEO_UPLOAD, {}, {}, form_Data);
                if (response.status === 200) {
                    // //console.log("response---", response);
                    let newDoc = {
                        key: response?.data?.data?.id?.key,
                        base_url: response?.data?.data?.id?.base_url,
                    }
                    allDoc.push(newDoc);
                    setExpenseDoc(allDoc)
                } else {
                    sweetAlerts('error')
                }
            }
            setIsLoading(false)
        } else {
            setIsLoading(false)
            sweetAlerts('error')
        }
    };
    const sweetAlerts = variant => {
        Swal.fire({
            icon: variant,
            title: variant,
            text: variant === "success" ? 'Expense Entered Successfully!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };
    return (
        <Formik
            enableReinitialize
            initialValues={{
                expenseAmount: expenseData?.expense_amount ? expenseData?.expense_amount : null,
                expenseDate: expenseData?.expense_date ? moment(expenseData?.expense_date).format('YYYY-MM-DD') : '',
                expenseDescription: expenseData?.expense_description ? expenseData?.expense_description : '',
                expenseNote: expenseData?.expense_note ? expenseData?.expense_note : '',
                category: expenseData?.category ? expenseData?.category : '',
            }}
            validationSchema={yup.object().shape({
                expenseAmount: yup.number()
                .typeError('Expense amount must be a number')
                .required('Expense amount is required')
                .test(
                'is-decimal',
                'Invalid format. Please enter a valid number ',
                value => (value === undefined || value === null ? true : /^(\d+)?(\.\d{1,2})?$/.test(value.toString()))
                ),
                // expenseDoc: yup.array().required('Please upload file'),
                expenseDate: yup.string().required('Expense Date is required'),
                expenseDescription: yup.string().required('Description is required'),
                expenseDate: yup.string().required('Expense Date is required'),
                category: yup.array()
                    .min(1, 'Please select at least one category') // Ensure at least one category is selected
                    .of(yup.string()) // Ensure all values in the array are strings
                    .required('Category is required') 
            })}
            onSubmit={(values) => {
                if (expenseDoc?.length == 0) { //values?.category?.length >= 1 && values?.category?.[0] != "rentals" && 
                    return false
                  }
                //console.log("onSubmit:", expenseDoc);
                let payload = {
                    expense_description: values?.expenseDescription,
                    expense_amount: values?.expenseAmount,
                    expense_date: values?.expenseDate,
                    bill_reciept_documents: expenseDoc,
                    status: "Requested",
                    expense_note: values?.expenseNote,
                    category: values?.category


                    // bill_reciept_documents: {
                    //     key: files,
                    //     base_url: files
                    // },
                }
                // //console.log("onSubmit payload:", payload);
                if (expanseId !== undefined) {
                    subTaskService.updateExpenseStatus(payload, expanseId).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                } else {
                    subTaskService.addExpensePostApi(payload).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                    sweetAlerts('success')
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values,
                dirty
            }) => {
                // //console.log('values------', values);

                const handleCategoryChange = (event) => {
                    const newValue = event.target.value;
                    setSelectedValues(newValue);

                    // If 'others' is selected, clear the otherValue state
                    if (newValue !== 'others') {
                        setOtherValue('');
                    }
                };

                const handleOtherInputChange = (event) => {
                    setOtherValue(event.target.value);
                };
                return (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <FormControl sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    value={values?.expenseAmount}
                                    placeholder="Expense amount.."
                                    label="Expense Amount"
                                    variant="outlined"
                                    name="expenseAmount"
                                    onChange={handleChange}
                                    style={{ marginBottom: '2px' }}

                                />
                                <ErrorMessage name="expenseAmount" component="div" style={{ color: 'red' }} />

                            </FormControl>
                            <FormControl sx={{ width: "-webkit-fill-available" }}>
                                <TextField
                                    id="expenseDate"
                                    label="Expense Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.expenseDate}
                                    style={{ marginBottom: '2px' }}
                                />
                                <ErrorMessage name="expenseDate" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.expenseDescription}
                                    placeholder="Description.."
                                    label="Description"
                                    variant="outlined"
                                    name="expenseDescription"
                                    multiline
                                    onChange={handleChange}
                                    style={{ marginBottom: '2px' }}
                                />
                                <ErrorMessage name="expenseDescription" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            
                            <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label" >Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Category"
                                    name='category'
                                    multiple
                                    value={values?.category || []} // Set a default empty array if values?.category is null or undefined

                                    renderValue={(selected) => selected.join(', ')}
                                    onChange={(e) => {
                                        const selectedValues = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
                                        setFieldValue('category', selectedValues);
                                    }}
                                >
                                    <MenuItem value={'travel'}>Travel</MenuItem>
                                    <MenuItem value={'food'}>Food</MenuItem>
                                    <MenuItem value={'accomodation'}>Accomodation</MenuItem>
                                    <MenuItem value={'rentals'}>Rentals</MenuItem>
                                    <MenuItem value={'internet'}>Internet</MenuItem>
                                    <MenuItem value={'subscriptions'}>Subscriptions</MenuItem>
                                    <MenuItem value={'others'}>Others</MenuItem>

                                </Select>
                                {selectedValues === 'others' && (
                                    <TextField
                                        label="Specify Category"
                                        value={otherValue}
                                        onChange={handleOtherInputChange}
                                        fullWidth
                                    />
                                )}
                                <ErrorMessage name="category" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.expenseNote}
                                    placeholder="Note.."
                                    label="Note"
                                    variant="outlined"
                                    name="expenseNote"
                                    multiline
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <JumboDemoCard
                                title={"Dropzone with preview"}
                            // demoCode={code}
                            >
                                <Div>
                                    <DndWrapper>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} name="expenseDoc"  />
                                            <Typography variant={"body1"}>Drag 'n' drop some files here, or click to select
                                                files</Typography>
                                        </div>
                                    </DndWrapper>
                                    <aside style={thumbsContainer}>{thumbs}</aside>
                                    {expenseDoc.map((doc, index) =>
                                        <a href={CLOUDFRONT_URL + doc?.base_url + "/" + doc?.key} target="_blank"><Typography variant={"h6"} mb={.5}>{index + 1}. {doc?.key}</Typography></a>

                                        // <aside style={thumbsContainer}>{doc}</aside>
                                    )}
                                </Div>
                            </JumboDemoCard>
                            {/* {
                                ((expenseDoc?.length == 0  &&  dirty)  ||  (values?.category?.length == 1 && values?.category?.[0] != "rentals" && dirty))   &&
                                <div style={{color:'red', marginTop:'2px'}}>Attachment is required</div>
                            } */}
                            {
                                (dirty && expenseDoc?.length == 0)
                                &&
                                <div style={{color:'red', marginTop:'2px'}}>Attachment is required</div>
                            }

                            {
                                isLoading ?
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        disabled={true}
                                        sx={{ mb: 3 }}
                                    // loading={isSubmitting || saveMutation.isLoading}
                                    >Save</LoadingButton> :
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                    // loading={isSubmitting || saveMutation.isLoading}
                                    >Save</LoadingButton>
                            }
                        </Div>
                    </Form>

                );
            }}
        </Formik>
    );
};
OfficeExpenseForm.defaultProps = {
    onSave: () => {
    }
};
export default OfficeExpenseForm;
